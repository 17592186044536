import { FC, useState } from "react";
import { Avatar, List, Input, Divider } from "antd";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { useTranslation } from "react-i18next";
import {
  SendOutlined,
  LoadingOutlined,
  UserOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { CommentItem, CommentsSectionProps } from "../types";

const { TextArea } = Input;

const CommentList = ({
  comments,
  onDelete,
}: {
  comments: CommentItem[];
  onDelete: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  return (
    <List
      dataSource={comments}
      header={t("comments")}
      itemLayout="horizontal"
      renderItem={(props) => (
        <>
          <div className="comment_contain">
            <div className="comment">
              <Avatar src={props.avatar} size={40} icon={<UserOutlined />} />
              <div className="comment_texts">
                <p className="comment_author">{props.author}</p>
                <p className="comment_content">{props.content}</p>
                <span className="comment_date">
                  {moment.utc(props.datetime).fromNow()}
                </span>
              </div>
            </div>
            {userData?.id === props.author_id && (
              <CloseOutlined
                onClick={() => onDelete(props.id)}
                className="comments_icon"
              />
            )}
          </div>
          <Divider />
        </>
      )}
    />
  );
};

const Editor = ({ onChange, onSubmit, submitting, value }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="create_comment_input">
        <TextArea
          rows={1}
          maxLength={500}
          showCount
          value={value}
          onChange={onChange}
          placeholder={t("Write a comment")}
          autoSize={{ minRows: 1, maxRows: 10 }}
          className="create_posts_input"
        />
        {submitting ? <LoadingOutlined /> : <SendOutlined onClick={onSubmit} />}
      </div>
    </>
  );
};

const CommentsSection: FC<CommentsSectionProps> = ({
  comments,
  postId,
  handleAddComment,
}) => {
  const [localComments, setLocalComments] = useState<CommentItem[]>(comments);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const { userData } = useSelector((state: RootState) => state.user);

  const handleSubmit = async () => {
    if (!value) return;

    setSubmitting(true);

    try {
      const response = await axios.post("/api/account/add_post_comment.php", {
        post_id: postId,
        content: value,
      });

      if (response.data.status === "success") {
        const newCommentId = response.data.comment_id;
        setLocalComments([
          ...localComments,
          {
            id: newCommentId,
            author_id: userData!.id,
            author: userData!.first_name,
            avatar: userData!.avatar_url,
            content: value,
            datetime: moment().utc().toISOString(),
          },
        ]);
        setValue("");
        handleAddComment(1);
      } else {
        console.error("Failed to add comment:", response.data.message);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteComment = async (id: number) => {
    setSubmitting(true);

    try {
      const response = await axios.post(
        "/api/account/delete_post_comment.php",
        {
          comment_id: id,
        }
      );

      if (response.data.status === "success") {
        setLocalComments(localComments.filter((comment) => comment.id !== id));
        handleAddComment(-1);
      } else {
        console.error("Failed to delete comment:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <div>
      {localComments.length > 0 && (
        <CommentList comments={localComments} onDelete={handleDeleteComment} />
      )}
      <div className="create_comment">
        <Avatar
          src={userData?.avatar_url}
          alt="User"
          size={30}
          icon={<UserOutlined />}
        />
        <Editor
          onChange={handleChange}
          onSubmit={handleSubmit}
          submitting={submitting}
          value={value}
        />
      </div>
    </div>
  );
};

export default CommentsSection;
